import { graphql } from 'gatsby'
import React from "react"
import Layout from '../../components/layout/index'
import PageHeader from '../../components/page/header'
import PostsGrid from '../../components/posts/grid'
import Seo from '../../components/seo'
import { getField } from '../../helper.js'

const Category = ({ data, pageContext: { locale = 'de', pathDe, pathEn } }) => {
  const { category, recipes } = data

  const title = getField(category, 'title', locale)
  const teaser = getField(category, 'teaser', locale)
  const metaTitle = getField(category, 'metaTitle', locale)
  const metaDescription = getField(category, 'metaDescription', locale)
  const keywords = getField(category, 'keywords', locale)
  const sharingImage = getField(category.coverImage, 'file', locale)?.src

  const localizedUrls = {
    de: pathDe,
    en: pathEn
  }

  const recipeNodes = recipes.edges.map(({ node }) => node)

  return (
    <>
      <Seo
        locale={locale}
        localizedUrls={localizedUrls}
        title={metaTitle}
        sharingTitle={title}
        sharingDescription={teaser}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        keywords={keywords}
        image={sharingImage}
      />
      <Layout locale={locale} localizedUrls={localizedUrls}>
        <PageHeader title={title} />
        <PostsGrid posts={recipeNodes} locale={locale} />
      </Layout>
    </>
  )
}

export default Category

export const query = graphql`
  fragment CategoryFragment on CMSCategory {
    __typename
    id
    titleDe
    titleEn
    teaserDe
    teaserEn
    metaTitleDe
    metaTitleEn
    metaDescriptionDe
    metaDescriptionEn
    keywordsDe
    keywordsEn
    coverImage {
      fileDe {
        src
      }
      fileEn {
        src
      }
    }
  }

  query CategoryQuery($id: String!) {
    category: cmsCategory(id: {eq: $id}) {
      ...CategoryFragment
    }

    recipes: allCmsRecipe(filter: {categories: {elemMatch: {id: {eq: $id}}}}, sort: {fields: publishDate, order: DESC}) {
      edges {
        node {
          ...RecipeTile
        }
      }
    }
  }
`
